var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":12}},[_c('div',[_c('div',{staticClass:"grid-content"},[_vm._v("\n            后台意见反馈\n          ")]),_c('div',{staticClass:"data"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"tac",attrs:{"span":8}},[_c('div',{class:_vm.flag?'all':'allpc'},[_vm._v("全部")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.dataNum.allFeedback))])]),_c('el-col',{staticClass:"tac",attrs:{"span":8}},[_c('div',{class:_vm.flag?'all':'allpc'},[_vm._v("新增")]),_c('div',{staticClass:"num"},[_vm._v("\n                  "+_vm._s(_vm.dataNum.newAddFeedback)+"\n                ")])]),_c('el-col',{staticClass:"tac",attrs:{"span":8}},[_c('div',{class:_vm.flag?'all':'allpc'},[_vm._v("待处理")]),_c('div',{staticClass:"num",style:('color:#E02525')},[_vm._v("\n                  "+_vm._s(_vm.dataNum.waitFinishFeedback)+"\n                ")])])],1)],1),_c('div',{staticClass:"pdpx"},[_c('div',{class:_vm.flag?'text':'textpc'},[_vm._v("问题描述")]),_c('div',{},[_c('vue-seamless-scroll',{staticClass:"seamless-warp",attrs:{"data":_vm.list,"class-option":_vm.optionSettings}},[_c('ul',{staticClass:"infinite-list"},_vm._l((_vm.list),function(i,indx){return _c('li',{key:indx,staticClass:"mr",class:{'infinite-list-item1':_vm.flag,'infinite-list-item1pc':!_vm.flag,'sun':i.state == 0,},style:({ color: i.state == 0 ? '#ffffff' : '#000000',
                    background: i.state == 0 ? '#FF0000' : '#ffffff'})},[_vm._v("\n                    "+_vm._s(i.feedbackDesc)+_vm._s(i.deviceInfo)),(i.replyContent&&i.state !== 0)?_c('div',{staticStyle:{"text-indent":"130px","color":"#89d961"}},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(i.replyUser)+"@")]),_vm._v(_vm._s(i.replyContent))]):_vm._e()])}),0)])],1)])])]),_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"grid-content"},[_vm._v("\n          工作内容\n        ")]),_c('div',{staticClass:"data"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"tac",attrs:{"span":8}},[_c('div',{class:_vm.flag?'all':'allpc'},[_vm._v("紧急")]),_c('div',{staticClass:"num",style:('color:#E02525')},[_vm._v("\n                "+_vm._s(_vm.dataNum.urgentJob)+"\n              ")])]),_c('el-col',{staticClass:"tac",attrs:{"span":8}},[_c('div',{class:_vm.flag?'all':'allpc'},[_vm._v("待开发")]),_c('div',{staticClass:"num",style:('color:#F6C659')},[_vm._v("\n                "+_vm._s(_vm.dataNum.waitStartJob)+"\n              ")])]),_c('el-col',{staticClass:"tac",attrs:{"span":8}},[_c('div',{class:_vm.flag?'all':'allpc'},[_vm._v("进行中")]),_c('div',{staticClass:"num",style:('color:#E02525')},[_vm._v("\n                "+_vm._s(_vm.dataNum.startingJob)+"\n              ")])])],1)],1),_c('div',{staticClass:"pdpx"},[_c('div',{class:_vm.flag?'text':'textpc',style:('display:flex;justify-content: space-between')},[_c('div',[_vm._v("需求")]),_c('div',[_vm._v("负责人")])]),_c('div',[_c('vue-seamless-scroll',{staticClass:"seamless-warp",attrs:{"data":_vm.jobLis,"class-option":_vm.optionSetting}},[_c('ul',{staticClass:"infinite-list"},_vm._l((_vm.jobLis),function(item,index){return _c('li',{key:index,class:_vm.flag?'infinite-list-item':'infinite-list-itempc',style:({
                    background:
                      item.level == 1 &&(item.state == 0||item.state == 1)
                        ? '#FF0000'
                        : item.state == 0
                        ? '#FFF922'
                        : item.state == 2
                        ? '#B3BAC1'
                        : '#ffffff',
                    color:
                     item.level == 1 &&(item.state == 0||item.state == 1)
                        ? '#ffffff'
                        : item.state == 0
                        ? '#000000'
                        : item.state == 2
                        ? '#6B6F74'
                        : '#000000',
                  })},[_c('div',{style:({
                      borderRight:
                        item.level == 1 && (item.state == 0||item.state == 1)
                          ? '2px solid #ffffff'
                          : item.state == 0
                          ? '2px solid #000000'
                          : item.state == 2
                          ? '2px solid #6B6F74'
                          : '2px solid #000000',
                      Width:_vm.flag? 'calc(68% - 20px)':'calc(75% - 20px)',minWidth:_vm.flag? 'calc(68% - 20px)':'calc(75% - 20px)'
                    })},[_vm._v("\n                    "+_vm._s(item.content)+"\n                  ")]),_c('div',{staticStyle:{"text-align":"center"},style:({minWidth:_vm.flag? '32%':'25%'})},[_vm._v("\n                    "+_vm._s(item.objectUser)+"\n                  ")])])}),0)])],1)])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }