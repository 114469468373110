<template>
  <div class="content">
    <div class="title">
      <el-row :gutter="24">
        <el-col :span="12">
          <div >
            <div class="grid-content">
              后台意见反馈
            </div>
            <div class="data">
              <el-row :gutter="20">
                <el-col :span="8" class="tac"
                  ><div  :class="flag?'all':'allpc'">全部</div>
                  <div class="num">{{ dataNum.allFeedback }}</div>
                </el-col>
                <el-col :span="8" class="tac"
                  ><div :class="flag?'all':'allpc'">新增</div>
                  <div class="num" >
                    {{ dataNum.newAddFeedback }}
                  </div></el-col
                >
                <el-col :span="8" class="tac"
                  ><div  :class="flag?'all':'allpc'">待处理</div>
                  <div class="num" :style="'color:#E02525'">
                    {{ dataNum.waitFinishFeedback }}
                  </div></el-col
                >
              </el-row>
            </div>
            <div class="pdpx">
              <div  :class="flag?'text':'textpc'">问题描述</div>

              <div class="">
                <vue-seamless-scroll
                  :data="list"
                  :class-option="optionSettings"
                  class="seamless-warp"
                >
                  <ul class="infinite-list" >
                    <li
                      v-for="(i,indx) in list"
                      :key="indx"
                      class="mr"
                      :class="{'infinite-list-item1':flag,'infinite-list-item1pc':!flag,'sun':i.state == 0,}"
                      :style="{ color: i.state == 0 ? '#ffffff' : '#000000',
                      background: i.state == 0 ? '#FF0000' : '#ffffff'}"
                    >
                      {{ i.feedbackDesc }}{{i.deviceInfo}}<div v-if="i.replyContent&&i.state !== 0" style="text-indent:130px;color:#89d961"><div style="white-space:nowrap;">{{i.replyUser}}@</div>{{i.replyContent}}</div>
                    </li>
                  </ul>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12"
          ><div class="grid-content">
            工作内容
          </div>
          <div class="data">
            <el-row :gutter="20">
              <el-col :span="8" class="tac"
                ><div  :class="flag?'all':'allpc'">紧急</div>
                <div class="num" :style="'color:#E02525'">
                  {{ dataNum.urgentJob }}
                </div>
              </el-col>
              <el-col :span="8" class="tac"
                ><div  :class="flag?'all':'allpc'">待开发</div>
                <div class="num" :style="'color:#F6C659'">
                  {{ dataNum.waitStartJob }}
                </div></el-col
              >
              <el-col :span="8" class="tac"
                ><div  :class="flag?'all':'allpc'">进行中</div>
                <div class="num" :style="'color:#E02525'">
                  {{ dataNum.startingJob }}
                </div></el-col
              >
            </el-row>
          </div>
          <div class="pdpx">
            <div
               :class="flag?'text':'textpc'"
              :style="'display:flex;justify-content: space-between'"
            >
              <div>需求</div>
              <div>负责人</div>
            </div>
            <div>
              <vue-seamless-scroll
                :data="jobLis"
                :class-option="optionSetting"
                class="seamless-warp"
              >
                <ul class="infinite-list">
                  <li
                    v-for="(item,index) in jobLis"
                    :key="index"
                     :class="flag?'infinite-list-item':'infinite-list-itempc'"
                    :style="{
                      background:
                        item.level == 1 &&(item.state == 0||item.state == 1)
                          ? '#FF0000'
                          : item.state == 0
                          ? '#FFF922'
                          : item.state == 2
                          ? '#B3BAC1'
                          : '#ffffff',
                      color:
                       item.level == 1 &&(item.state == 0||item.state == 1)
                          ? '#ffffff'
                          : item.state == 0
                          ? '#000000'
                          : item.state == 2
                          ? '#6B6F74'
                          : '#000000',
                    }"
                  >
                    <div
                      :style="{
                        borderRight:
                          item.level == 1 && (item.state == 0||item.state == 1)
                            ? '2px solid #ffffff'
                            : item.state == 0
                            ? '2px solid #000000'
                            : item.state == 2
                            ? '2px solid #6B6F74'
                            : '2px solid #000000',
                        Width:flag? 'calc(68% - 20px)':'calc(75% - 20px)',minWidth:flag? 'calc(68% - 20px)':'calc(75% - 20px)'
                      }"
                    >
                      {{ item.content }}
                    </div>
                    <div
                      style="
                       text-align: center;"
                      :style="{minWidth:flag? '32%':'25%'}"
                    >
                      {{ item.objectUser }}
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { ProblemFeedback } from ".././config/request";
import vueSeamless from "vue-seamless-scroll";
export default {
  components: {
    vueSeamless,
  },
  filters: {},
  data() {
    return {
      list: [],
      dataNum: {},
      jobLis: [],
      flag:false
    };
  },
  computed: {
    optionSetting() {
      return {
        step: 0.7, // 数值越大速度滚动越快
        limitMoveNum: this.jobLis.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
     optionSettings() {
      return {
        step: 0.7, // 数值越大速度滚动越快
        limitMoveNum: this.list.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
mounted () {
    this.ScrollEnd()
   // 根据不同路由跳转不同页面
     this.flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  //true是手机端
   if (this.flag) {
     console.log('手机端')
   } else {
     console.log('pc端')
   }
 },
 methods: {
   // 判断是否是手机端，如果是，返回true
   
    // 获取轮播图
    queryList() {
      ProblemFeedback.queryList({
        rows:1,
        size:100
      }).then((res) => {
        // console.log(res)
        if (res.code == 200) {
          res.data.map((i=>{
            if(i.deviceInfo&&i.deviceInfo.indexOf("weChat") != -1){
              i.deviceInfo="--微信端"
            }else if(i.deviceInfo&&i.deviceInfo.indexOf("iOS") != -1){
              i.deviceInfo="--苹果端"
            }else{
              i.deviceInfo="--安卓端"
            }
          }))
          this.list = res.data;
        }
      });
    },
    queryCount() {
      ProblemFeedback.queryCount({
        // type:3
      }).then((res) => {
        // console.log(res)
        if (res.code == 200) {
          this.dataNum = res.data;
        }
      });
    },
    queryJob() {
      ProblemFeedback.queryJob({}).then((res) => {
        // console.log(res)
        if (res.code == 200) {
          this.jobLis = res.data;
        }
      });
    },
    ScrollEnd(){
       this.list=[],
      this.dataNum={},
      this.jobLis= [],
    this.queryList();
    this.queryCount();
    this.queryJob();
    setTimeout(()=>{
    this.ScrollEnd()
    },300000)
this.$forceUpdate()
  },
  },
 
  destroyed() {},
};
</script>

<style>
.content {
  overflow: hidden;
  height: 100vh;
  background:#001630;
}
.title {
  padding-top: 20px;
  padding-left: 20px;
  
}
.grid-content {
  font-size: 70px;
  /* background-color: #f7f7f9; */
  padding: 23px 0 22px 23px;
  color: #C3D3DE;
  font-weight: 900;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Black;
}
.data {
  padding: 30px 0 30px 20px;
}
.allpc {
  font-size: 68px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Medium;
  font-weight: 500;
  text-align: left;
  color: #C3D3DE;
  white-space: nowrap;
}
.all {
  font-size: 38px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Medium;
  font-weight: 500;
  text-align: left;
  color: #C3D3DE;
  white-space: nowrap;
}
.tac{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.num {
  font-size: 80px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Medium;
  font-weight: 500;
  color: #C3D3DE;
}
.pdpx {
  padding-right: 30px;
}
.textpc {
  padding: 20px 30px 20px 60px;
  font-size: 60px;
  font-weight: 500;
  color: #C3D3DE;
  /* background: #ffffff; */
  /* border: 1px solid #ffffff; */
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}
.text {
  padding: 20px 20px 20px 20px;
  font-size: 60px;
  font-weight: 500;
  color: #C3D3DE;
  /* background: #ffffff; */
  /* border: 1px solid #ffffff; */
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}

.infinite-list-itempc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-right: 0;
  font-size: 65px;
  font-weight: 500;
  color: #ff0000;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  width: 100%;

}
.infinite-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-right: 0;
  font-size: 40px;
  font-weight: 500;
  color: #ff0000;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  width: 100%;

}
.infinite-list-item1pc {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 20px;
  padding-right: 0;
  font-size: 65px;
  font-weight: 500;
  color: #ff0000;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  width: 100%;
}
.infinite-list-item1 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 20px;
  padding-right: 0;
  font-size: 40px;
  font-weight: 500;
  color: #ff0000;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  width: 100%;
}
@keyframes fade {
    from {        
    opacity: 1.0;    
    }
    50% {        
    opacity: 0.4;    
    }
    to {        
    opacity: 1.0;    
    }
}
.sun{
   animation: fade 600ms infinite;    
    -webkit-animation: fade 1200ms infinite;
}
.seamless-warp {
  overflow: hidden;
}
.mr {
  padding-right: 20px !important;

}
</style>
